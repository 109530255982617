<template>
  <div class="blog-detail-wrapper">

    <b-row class="blog-list-wrapper">
      <b-card no-body class="col-md-12">
        <b-card-header class="pb-50">
          <h5>
            Фильтр
          </h5>
        </b-card-header>
        <b-card-body>
          <b-row>
            <b-col
                cols="12"
                md="12"
                class="mb-md-0 mb-2"
            >
              <label>Тип списков</label>
              <v-select
                  v-model="type"
                  :options="typeOptions"
                  class="w-100"
                  :reduce="val => val.value"
                  :clearable="false"
              />
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>

      <b-overlay
          :show="listLoading"
          rounded="sm"
      >
        <b-col cols="12" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
          <b-table
              :items="topicsList"
              responsive
              :fields="tableColumns"
              primary-key="filmId"
              show-empty
              empty-text="No matching records found"
              class="position-relative col-12"
          >

            <!-- Column: Topic -->
            <template #cell(topic)="data">
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.title }}
            </span>
            </template>

            <!-- Column: Client -->
            <template #cell(lists)="data">
              <v-select
                  v-model="data.item.lists"
                  multiple
                  label="title"
                  :options="lists"
                  :reduce="val => val.id"
                  @input="updateItem(data.item)"
              />
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <div class="text-nowrap">
                <b-button
                    variant="danger"
                    class="btn-icon"
                    size="sm"
                >
                  <feather-icon icon="TrashIcon"/>
                </b-button>
              </div>
            </template>
          </b-table>


        </b-col>

      </b-overlay>

    </b-row>

    <b-row class="mb-1">
      <b-col cols="12">
        <b-badge
            v-for="list in freeLists"
            key="id"
            variant="light-success"
            class="ml-1 xl text-large"

        >
          {{ list.title }}
        </b-badge>
      </b-col>
    </b-row>

    <b-overlay
        :show="createItemLoading"
        rounded="sm"
    >
      <b-row class="mb-1">
        <b-col cols="12">
          <b-form-input
              v-model="newTopicName"
              placeholder="Название"
          />
        </b-col>
        <b-col cols="12" class="mt-1">
          <b-button
              type="submit"
              variant="success"
              block
              @click.prevent="createItem(newTopicName)"
          >
            Добавить
          </b-button>
        </b-col>
      </b-row>
    </b-overlay>

    <!--/ blogs -->

  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BCardHeader,
  BTable,
  BOverlay,
  BButton, VBTooltip,
} from 'bootstrap-vue'
import {avatarText, badWord, kFormatter, truncateString} from '@core/utils/filter'
import store from "@/store";
import {onUnmounted, ref, watch} from "@vue/composition-api";
import topicsStoreModule from "@/views/topics/topicsStoreModule";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
    BCardHeader,
    BTable,
    BButton,
    BOverlay,
    vSelect,
  },
  data() {
    return {
      blogSidebar: {},
      disabled: 0,
      newTopicName: '',
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  methods: {},
  setup() {
    const STORE_MODULE_NAME = 'topics'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, topicsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    // Table Handlers
    const tableColumns = [
      {key: 'id', label: '#', sortable: false},
      {key: 'topic', sortable: true},
      {key: 'items_count', sortable: true},
      {key: 'lists', sortable: false},
      {key: 'actions'},
    ]

    const type = ref('music')
    const typeOptions = [
      { label: 'Музыка', value: 'music' },
      { label: 'Фильмы', value: 'movies' },
      { label: 'Сериалы', value: 'series' },
    ]

    const topicsList = ref([])
    const createItemLoading = ref(false)
    const listLoading = ref(false)
    const lists = ref([])
    const freeLists = ref([])
    const fetchTopics = (...args) => store.dispatch('topics/fetchTopics', ...args)
    const updateTopic = (...args) => store.dispatch('topics/updateAutotopic', ...args)
    const addTopic = (...args) => store.dispatch('topics/createAutotopic', ...args)

    const fetchTopicsList = (type) => {
      listLoading.value = true;
      fetchTopics({queryParams: {type: type.value}})
          .then(response => {
            topicsList.value = response.data.data
            lists.value = response.data.lists
            freeLists.value = response.data.free_lists
            listLoading.value = false;
          })
    }

    const updateItem = (autotopic) => {
      updateTopic({id: autotopic.id, queryParams: autotopic})
          .then(response => {
            console.log('TRUE')
          })
    }

    const createItem = (name) => {
      createItemLoading.value = true
      addTopic({queryParams: {title: name, type: type.value}}).then(response => {
        topicsList.value.push(response.data.data)
        createItemLoading.value = false
        this.newTopicName = ''
      })
    }

    fetchTopicsList(type)

    watch([type], () => {
      fetchTopicsList(type)
    }, {
      deep: true,
    })

    return {
      fetchTopics,
      fetchTopicsList,
      tableColumns,
      topicsList,
      lists,
      freeLists,
      avatarText,
      updateItem,
      createItem,
      createItemLoading,
      listLoading,

      typeOptions,
      type,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-blog.scss';
</style>
