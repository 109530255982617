import { render, staticRenderFns } from "./TopicsList.vue?vue&type=template&id=216f0c80&"
import script from "./TopicsList.vue?vue&type=script&lang=js&"
export * from "./TopicsList.vue?vue&type=script&lang=js&"
import style0 from "./TopicsList.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports